import React, { useState } from 'react';
import Web3Context from '../../context/Web3Context'
import { nets, WHALEX_API } from '../../config'
import WhaleXABI from '../../abi/WhaleXABI'
import { toWei } from 'web3-utils';
import axios from 'axios';
import UniV2RouterABI from '../../abi/UniV2RouterABI'

function CreateRealLiqudity() {
  const context = React.useContext(Web3Context)
  const web3 = context.web3
  const accounts = context.accounts
  const netId = Number(context.netId)
  
  const [isUseAdvanced, setUseAdvanced] = useState(false)
  const [formData, setFormData] = useState(
    { 
      name: '', 
      symbol: '', 
      supply: 0, 
      net:'tbnb',
      image: null,
      liquidity:0
    }
  );

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image') {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    console.log(formData); // Handle form submission logic here
  };

  const createPum = async (web3, accounts) => {
    if(!web3){
      alert("Please connect wallet")
      return 
    }

    if(!nets[formData.net].whaleX){
      alert(`Please whaleX dex not exist on ${nets[formData.net].symbol} net`)
      return
    }

    if(nets[formData.net].netID !== netId){
      alert(`Please switch wallet to ${nets[formData.net].symbol}`)
      return
    }

    if(formData.name.length === 0){
      alert("Please input name")
      return
    }

    if(formData.symbol.length === 0){
      alert("Please input symbol")
      return
    }

    if(!formData.image){
      alert("Please drop image")
      return
    }

    let uri

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('image', formData.image)

      const response = await axios.post(`${WHALEX_API}/upload`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      uri = response.data.file

      console.log('File uploaded successfully', response.data);
    } catch (error) {
      alert("Can not upload image")
      console.error('Error uploading file', error)
      return
    }

    const factory = new web3.eth.Contract(WhaleXABI, nets[formData.net].whaleX)

    let _liquidity
    let _supply
    
    // custom supply and liquidity
    if(isUseAdvanced){
      if(formData.supply <= 0){
        alert("Please input supply")
        return
      }
      if(formData.liquidity <= 0){
        alert("Please input liquidity")
        return
      }

      _liquidity = toWei(String(formData.liquidity))
      _supply = toWei(String(formData.supply))
    }
    // defualt platform setting
    else{
     const router = new web3.eth.Contract(UniV2RouterABI, nets[formData.net].v2Router)
     const rate = await router.methods.getAmountsOut(
      nets[formData.net].realDAILiq,
      [nets[formData.net].dai, nets[formData.net].weth]
     ).call()

     if(rate[1] < 0){
      alert("Wrong rate")
      return
     }


     _liquidity = rate[1]
     _supply = nets[formData.net].realTotalSupply
    }

    console.log(_liquidity, _supply)

    const txObj = { 
      from:accounts[0], 
      gasLimit:12500848, 
      value: _liquidity,
      gasPrice: await web3.eth.getGasPrice()
    }
    
    factory.methods.createSplit(
      formData.name, 
      formData.symbol, 
      _supply,
      50,
      50,
      uri
    ).send(txObj)
  }

  return (
    <div className="App">
        <form className="form" onSubmit={handleSubmit}>
          <label className="label">
            Name:
            <input className="input" type="text" name="name" value={formData.name} onChange={handleInputChange} />
          </label>
          <br />
          <label className="label">
            Symbol:
            <input className="input" type="text" name="symbol" value={formData.symbol} onChange={handleInputChange} />
          </label>
          <br />
          <div>
            <label>
            <input
              type="checkbox"
              checked={isUseAdvanced}
              onChange={(e) => setUseAdvanced(!isUseAdvanced)}
            />
            advanced
           </label>
          </div>
          {
            isUseAdvanced
            ?
            (
             <>
             <label className="label">
               Supply:
               <input className="input" type="number" name="supply" value={formData.supply} onChange={handleInputChange} />
             </label>
             <br />
             <label className="label">
               Liquidity:
               <input className="input" type="number" name="liquidity" value={formData.liquidity} onChange={handleInputChange} />
             </label>
             </>
            )
            : null
          }
          
          <br />
          <label className="label">
            Image:
            <input className="input" type="file" name="image" onChange={handleInputChange} />
          </label>
          <br />
          <label className="label">
            Network:
            <select className="input" name="net" value={formData.net} onChange={handleInputChange}>
            {
                Object.entries(nets).map(([key, value]) => (
                    <option key={key} value={key}>{value.symbol}</option>
                ))
            }
            </select>
          </label>
          <br />
          <button className="submitButton" onClick={() => createPum(web3, accounts)}>Create</button>
        </form>
    </div>
  );
}

export default CreateRealLiqudity;