export const isDEV = false

export const WHALEX_API = isDEV ? "http://localhost:9127" : "https://whalex-api.ai42.tv" 
export const PUMP_API = isDEV ? "http://localhost:9124" : "https://pump-api.ai42.tv" 
export const PUMP_CHARTS_API = isDEV ? "http://localhost:9100" : "https://aggregator-api.ai42.tv"
export const WHALEX_CHARTS_API = isDEV ? "http://localhost:9101" : "https://whalex-aggregator-api.ai42.tv"
export const SOCIAL_API = isDEV ? "http://localhost:9125" : "https://pump-social-api.ai42.tv"


export const nets = {
    tbnb:{
      whaleX:"0x6478EffceA28800BB4f6b4d70dB506b91E10C1fe",
      factory:"0xCCBCC24154Ce4C07C66462a2948BB0419E02cbBf",
      v2Router:"0xcCb6973225FE7e61B97E0fe1a7822EEc56884d92",
      dai:"0xd46D91319364F57775E23E39d046A398BED26F92",
      weth:"0x1c4ed1f3d87e5a6100cffd37b2a4e739f5b6bdd9",
      daiInitial:"10000000000000000000000", // 10K$
      netID:97,
      symbol: "BNB Testnet",
      rpc: "https://bsc-testnet-dataseed.bnbchain.org",
      realDAILiq: "3000000000000000000", // 3$
      virtTotalSupply: "1000000000000000000000000000", // 1B $
      realTotalSupply: "1000000000000000000000000000" // 1B $
    },
    base:{
      whaleX:"0xf04A0aE421C5f7b8B02bB30574Fb8b8c58c57d2C",
      factory:"0x3344573A8b164D9ed32a11a5A9C6326dDB3dC298",
      v2Router:"0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24",
      dai:"0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
      weth:"0x4200000000000000000000000000000000000006",
      daiInitial:"10000000000000000000000", // 10K$
      netID:8453,
      symbol: "BASE",
      rpc: "https://base.llamarpc.com",
      realDAILiq: "3000000000000000000", // 3$
      virtTotalSupply: "1000000000000000000000000000", // 1B $
      realTotalSupply: "1000000000000000000000000000" // 1B $
    },
    bnb:{
      whaleX:null,
      factory:"0x61AB60249cf8CAb5c5aa59CE3906c0Cd5db28703",
      v2Router:"0x10ED43C718714eb63d5aA57B78B54704E256024E",
      dai:"0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      weth:"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      daiInitial:"10000000000000000000000", // 10K$
      netID:56,
      symbol: "BNB",
      rpc: "https://bsc.drpc.org",
      realDAILiq: "3000000000000000000", // 3$
      virtTotalSupply: "1000000000000000000000000000", // 1B $
      realTotalSupply: "1000000000000000000000000000" // 1B $
    }
}
